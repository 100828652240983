<template>
  <div class="create-event-modal">
    <div class="create-event-modal__frame">
      <div class="create-event-modal__header">
        <div class="create-event-modal__heading">
          Create new event
        </div>
        <div class="create-event-modal__text">
          Please provide needed information
        </div>
      </div>
      <div
        v-if="!isInitialized"
        class="create-event-modal__loader"
      >
        <Spinner />
      </div>
      <div
        v-else
        class="create-event-modal__form"
      >
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Sport
          </div>
          <Select
            variant="secondary"
            :model-value="selectedSportId"
            :options="sports"
            :disabled="isSportsDisabled"
            @update:model-value="setSelectedSportId"
            placeholder="Sport"
            omit-prefix
          />
        </div>
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Competition
          </div>
          <SearchSelect
            :model-value="selectedCompetitionId"
            :options="competitions"
            :is-loading="isLoadingCompetitions"
            :is-disabled="isCompetitionsDisabled"
            @update:model-value="setSelectedCompetitionId"
            @update:options="loadCompetitions"
            placeholder="Competition"
            :auto-focus="true"
          />
        </div>
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Home team
          </div>
          <SearchSelect
            :model-value="selectedHomeTeamId"
            :options="teams"
            :error="teamInputError"
            :is-loading="isLoadingTeams"
            :is-disabled="isTeamsDisabled"
            @update:model-value="setSelectedHomeTeamId"
            @update:options="loadTeams"
            placeholder="Home Team"
            :auto-focus="true"
          />
          <div
            v-if="teamInputError"
            class="create-event-modal__form-error"
          >
            <Icon
              class="create-event-modal__form-error-icon"
              name="exclamation"
            />
            <span class="create-event-modal__form-error-message">
              Please select different teams.
            </span>
          </div>
        </div>
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Away team
          </div>
          <SearchSelect
            :model-value="selectedAwayTeamId"
            :options="teams"
            :error="teamInputError"
            :is-loading="isLoadingTeams"
            :is-disabled="isTeamsDisabled"
            @update:model-value="setSelectedAwayTeamId"
            @update:options="loadTeams"
            placeholder="Away Team"
            :auto-focus="true"
          />
        </div>
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Date
          </div>
          <DatePicker
            class="create-event-modal__form-control"
            :model-value="selectedDate"
            @update:model-value="setSelectedDate"
            :disabled="isDateDisabled"
            :error="selectedDateError"
            hide-placeholder
          />
          <div
            v-if="selectedDateError"
            class="create-event-modal__form-error"
          >
            <Icon
              v-if="selectedDateError !== ' '"
              class="create-event-modal__form-error-icon"
              name="exclamation"
            />
            <span class="create-event-modal__form-error-message">
              {{ selectedDateError }}
            </span>
          </div>
        </div>
        <div class="create-event-modal__form-group">
          <div class="create-event-modal__form-label">
            Time
          </div>
          <TimeInput
            :model-value="selectedTime"
            @update:model-value="setSelectedTime"
            :disabled="isTimeDisabled"
            :error="selectedTimeError"
          />
          <div
            v-if="selectedTimeError"
            class="create-event-modal__form-error"
          >
            <Icon
              v-if="selectedTimeError !== ' '"
              class="create-event-modal__form-error-icon"
              name="exclamation"
            />
            <span class="create-event-modal__form-error-message">
              {{ selectedTimeError }}
            </span>
          </div>
        </div>
      </div>
      <div class="create-event-modal__footer">
        <Button
          variant="tertiary"
          @click="closeModal"
        >
          Dismiss
        </Button>
        <Button
          variant="primary"
          :disabled="!isAllowedToSubmit || isProcessing"
          :loading="isProcessing"
          @click="submitEvent"
        >
          Create
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  drop,
  map,
  split,
  includes,
} from 'lodash';
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import {
  parseISO,
  format,
  addHours,
  isBefore,
} from 'date-fns';
import { useStore } from 'vuex';
import { findAllSports, findCompetitionsBySportId, findSimpleTeamsBySportId } from '@/services/api/graphql';
import { convertTo24HourTime } from '@/services/helpers/time';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import Spinner from '@/components/common/Spinner';
import DatePicker from '@/components/common/DatePicker';
import SearchSelect from '@/components/common/SearchSelect';
import TimeInput from '@/components/common/TimeInput';
import { createManualEvent } from '@/services/api/charon';

export default {
  components: {
    Icon,
    Button,
    Select,
    Spinner,
    DatePicker,
    SearchSelect,
    TimeInput,
  },
  props: {
    initialSportId: {
      type: String,
      default: '',
    },
    initialCompetitionId: {
      type: String,
      default: '',
    },
  },
  emits: {
    onClose: {},
    onSubmit: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const isInitialized = ref(false);
    const isProcessing = ref(false);
    const now = ref(new Date());
    const nowIntervalId = ref(0);

    const sports = ref([]);
    const selectedSportId = ref('');
    const isLoadingSports = ref(false);
    const isSportsDisabled = computed(() => isProcessing.value || isLoadingSports.value);
    const loadSports = () => findAllSports()
      .then((payload) => {
        sports.value = map(drop(payload), (sport) => ({
          value: sport.sportId,
          label: sport.sportName,
        }));
      });
    const setSelectedSportId = (newSelectedSportId) => {
      selectedSportId.value = newSelectedSportId;
    };

    const competitions = ref([]);
    const selectedCompetitionId = ref(null);
    const isLoadingCompetitions = ref(false);
    const isCompetitionsDisabled = computed(() => isProcessing.value || !selectedSportId.value);
    const loadCompetitions = (searchQuery) => {
      if (!selectedSportId.value) return;
      isLoadingCompetitions.value = true;
      // eslint-disable-next-line consistent-return
      return findCompetitionsBySportId({
        sportId: selectedSportId.value,
        search: searchQuery || '',
      })
        .then((payload) => {
          competitions.value = map(payload, (competition) => ({
            value: competition.competitionId,
            label: competition.competitionName,
          }));
        })
        .finally(() => {
          isLoadingCompetitions.value = false;
        });
    };
    const setSelectedCompetitionId = (newSelectedCompetitionId) => {
      selectedCompetitionId.value = newSelectedCompetitionId;
    };

    const teams = ref([]);
    const isLoadingTeams = ref(false);
    const selectedHomeTeamId = ref(null);
    const selectedAwayTeamId = ref(null);
    const isTeamsDisabled = computed(() => isProcessing.value || !selectedSportId.value);
    const teamInputError = computed(() => {
      if (!selectedHomeTeamId.value && !selectedAwayTeamId.value) return '';
      if (selectedHomeTeamId.value?.value !== selectedAwayTeamId.value?.value) return '';
      return 'Cannot select the same team for home and away.';
    });
    const loadTeams = (searchQuery) => {
      if (!selectedSportId.value) return;
      isLoadingTeams.value = true;
      findSimpleTeamsBySportId({
        sportId: selectedSportId.value,
        search: searchQuery || '',
      })
        .then((response) => {
          teams.value = map(response.data?.teams?.nodes || [], (team) => ({
            value: team.id,
            label: team.name,
          }));
        })
        .finally(() => {
          isLoadingTeams.value = false;
        });
    };
    const setSelectedHomeTeamId = (newSelectedHomeTeamId) => {
      selectedHomeTeamId.value = newSelectedHomeTeamId;
    };
    const setSelectedAwayTeamId = (newSelectedAwayTeamId) => {
      selectedAwayTeamId.value = newSelectedAwayTeamId;
    };

    const selectedDate = ref('');
    const isDateDisabled = computed(() => isProcessing.value);
    const setSelectedDate = (newSelectedDate) => {
      selectedDate.value = newSelectedDate;
    };

    const selectedTime = ref('');
    const isTimeDisabled = computed(() => isProcessing.value);
    const setSelectedTime = (newSelectedTime) => {
      selectedTime.value = newSelectedTime;
    };

    const selectedDateError = computed(() => {
      const [time] = split(selectedTime.value, ' ');
      if (!selectedDate.value || !time || time === '00:00') return '';
      const dateTime = parseISO(`${selectedDate.value}T${convertTo24HourTime(selectedTime.value)}:00`);
      if (isBefore(dateTime, now.value)) return 'Date and time must be in future';
      return '';
    });
    const selectedTimeError = computed(() => {
      const [time] = split(selectedTime.value, ' ');
      if (time === '00:00') return 'Invalid value. Did you mean 12:00?';
      if (!time) return '';
      const [hours, minutes] = split(time, ':').map(Number);
      const isValidTime = selectedTime.value?.length === 8 && hours > 0 && hours <= 12 && minutes >= 0 && minutes <= 59;
      if (!isValidTime) return 'Invalid value';
      const dateTime = parseISO(`${selectedDate.value}T${convertTo24HourTime(selectedTime.value)}:00`);
      if (isBefore(dateTime, now.value)) return ' ';
      return '';
    });

    const isAllowedToSubmit = computed(() => {
      const isValidSelectedSport = !!selectedSportId.value;
      const isValidSelectedCompetition = !!selectedCompetitionId.value?.value;
      const isValidSelectedHomeTeam = !!selectedHomeTeamId.value?.value && selectedHomeTeamId.value?.value !== selectedAwayTeamId.value?.value;
      const isValidSelectedAwayTeam = !!selectedAwayTeamId.value?.value && selectedHomeTeamId.value?.value !== selectedAwayTeamId.value?.value;
      const isValidDate = !!selectedDate.value;
      const [time, period] = split(selectedTime.value, ' ');
      const [hours, minutes] = split(time, ':').map(Number);
      const isValidTime = selectedTime.value?.length === 8 && hours > 0 && hours <= 12 && minutes >= 0 && minutes <= 59;
      const isValidPeriod = includes(['AM', 'PM'], period);
      const isValidDateTime = !selectedDateError.value && !selectedTimeError.value;
      return isValidSelectedSport && isValidSelectedCompetition && isValidSelectedHomeTeam && isValidSelectedAwayTeam && isValidDate && isValidTime && isValidPeriod && isValidDateTime;
    });

    const closeModal = () => {
      emit('onClose');
    };
    const submitEvent = () => {
      isProcessing.value = true;
      const payload = {
        competitionId: selectedCompetitionId.value.value,
        homeTeamId: selectedHomeTeamId.value.value,
        awayTeamId: selectedAwayTeamId.value.value,
        startsAt: parseISO(`${selectedDate.value}T${convertTo24HourTime(selectedTime.value)}:00`),
      };

      const currentDate = new Date();
      const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
      payload.startsAt = format(addHours(payload.startsAt, timezoneHoursOffset), 'yyyy-LL-dd\'T\'HH:mm:ss');

      createManualEvent(payload)
        .then(() => {
          store.dispatch('addNotification', {
            message: 'Event successfully created!',
            type: 'success',
            duration: 5000,
          });
          emit('onSubmit');
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: 'Something went wrong when creating event. Please try again later.',
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          isProcessing.value = false;
        });
    };

    onMounted(async () => {
      await loadSports();
      isInitialized.value = true;
      nowIntervalId.value = setInterval(() => {
        now.value = new Date();
      }, 1000);
    });
    onUnmounted(() => {
      clearInterval(nowIntervalId.value);
      nowIntervalId.value = 0;
    });

    watch(
      () => selectedSportId.value,
      (newSelectedSportId) => {
        if (!newSelectedSportId) return;

        selectedCompetitionId.value = null;
        competitions.value = [];
        loadCompetitions();

        selectedHomeTeamId.value = null;
        selectedAwayTeamId.value = null;
        teams.value = [];
        loadTeams();
      },
    );

    return {
      isInitialized,
      isProcessing,
      sports,
      selectedSportId,
      isLoadingSports,
      isSportsDisabled,
      loadSports,
      setSelectedSportId,
      competitions,
      selectedCompetitionId,
      isLoadingCompetitions,
      isCompetitionsDisabled,
      loadCompetitions,
      setSelectedCompetitionId,
      teams,
      isLoadingTeams,
      isTeamsDisabled,
      loadTeams,
      selectedHomeTeamId,
      selectedAwayTeamId,
      teamInputError,
      setSelectedHomeTeamId,
      setSelectedAwayTeamId,
      selectedDate,
      isDateDisabled,
      setSelectedDate,
      selectedTime,
      isTimeDisabled,
      setSelectedTime,
      selectedDateError,
      selectedTimeError,
      isAllowedToSubmit,
      closeModal,
      submitEvent,
    };
  },
};
</script>

<style lang="scss">
.create-event-modal {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  .select {
    .select-dropdown {
      max-height: 200px;

      .select-dropdown-item {
        flex-shrink: 0;
      }
    }
  }

  .date-picker {
    height: 32px;
    max-height: 32px;
    min-height: 32px;
  }

  &__frame {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    cursor: default;
    width: 480px;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    height: 74px;
    border-bottom: 1px solid #F0F0F0;

    .icon {
      stroke: #000;
      cursor: pointer;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 264px;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    grid-template-rows: repeat(3, auto);
    padding: 16px 48px 16px 16px;
    gap: 16px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 2px;
      // height: 46px;
      // min-height: 46px;
      // max-height: 46px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 11.85px;
      color: #6D6D6D;
    }

    &-error {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 10px;
      color: $error500;
      margin-top: 4px;

      &-icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        overflow: hidden;

        svg {
          background-color: $error500;
          fill: #fff;
          width: 100%;
          height: 100%;
        }
      }

      &-message {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    height: 64px;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
  }

  &__heading {
    font-size: 15.72px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: $gray700;
  }
}
</style>
