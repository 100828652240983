<template>
  <TextInput
    class="search-field"
    :model-value="search"
    @update:modelValue="updateSearch"
    icon="search"
    :placeholder="placeholder"
    small
  />
</template>

<script>
import { debounce } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import TextInput from '@/components/common/TextInput';

export default {
  components: {
    TextInput,
  },
  props: {
    placeholder: {
      required: true,
      type: String,
    },
  },
  setup() {
    const store = useStore();

    return {
      search: computed(() => store.getters.search),
      updateSearch: debounce((value) => {
        store.dispatch('updateSearch', value);
      }, 250),
    };
  },
};
</script>
<style lang="scss">
.search-field {
  width: 244px;
  .icon {
    stroke: $gray600;
  }
}
</style>
