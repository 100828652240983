<template>
  <div class="filters-dropdown-filter">
    <div class="filters-dropdown-filter-group">
      <div class="filters-dropdown-filter-value">
        <DatePicker
          v-if="model.inputType === 'date'"
          :model-value="value"
          @update:model-value="(updatedValue) => addOrUpdateFilter(updatedValue)"
          placeholder="Date"
          range
        />

        <Select
          v-else-if="model.inputType === 'select'"
          :model-value="filter.value"
          :options="model.inputArgs"
          :placeholder="model.label"
          :multiselect="model.selectionType !== 'single'"
          :variant="model.selectionType === 'single' ? 'secondary' : 'primary'"
          :show-footer-action="true"
          @update:model-value="(updatedValue) => addOrUpdateFilter(updatedValue)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { find, findIndex } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Select from '@/components/common/Select';
import DatePicker from '@/components/common/DatePicker';

export default {
  components: {
    Select,
    DatePicker,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const field = computed(() => find(store.getters.eventListFilters, { field: props.filter.field }));
    const modifierOptions = computed(() => store.getters.eventListFilterModifiers[props.model.inputType]);
    const modifier = computed(() => find(modifierOptions.value, { value: props.filter.modifier }));
    const value = computed(() => {
      switch (props.model.inputType) {
      case 'select':
        return find(props.model.inputArgs, { value: props.filter.value });
      default:
        return props.filter.value;
      }
    });

    const removeFilter = () => {
      store.dispatch('removeFilter', props.filter);
    };

    const addOrUpdateFilter = (filterValue) => {
      if (!filterValue) {
        removeFilter();
        return;
      }
      const filterIndex = findIndex(store.getters.eventListAppliedFilters, { field: props.model.key });
      if (filterIndex === -1) {
        store.dispatch('addFilter', {
          field: props.model.key,
          modifier: store.getters.eventListFilterModifiers[props.model.inputType][0].value,
          value: filterValue,
          inputType: props.filter.inputType,
        });
      } else {
        store.dispatch('updateFilter', {
          decodedFilter: props.filter,
          updateField: props.model.key,
          updatedValue: filterValue,
          modifier: store.getters.eventListFilterModifiers[props.model.inputType][0].value,
          inputType: props.filter.inputType,
        });
      }
    };

    return {
      field,
      modifier,
      modifierOptions,
      value,
      addOrUpdateFilter,
      removeFilter,
    };
  },
};
</script>

<style lang="scss">
.filters-dropdown-filter {
  display: flex;
  align-items: center;
  height: 100%;

  .filters-dropdown-filter-group,
  .filters-dropdown-filter-field,
  .filters-dropdown-filter-modifier,
  .filters-dropdown-filter-value {
    margin: 0 2px;
  }

  .filters-dropdown-filter-group {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .filters-dropdown-filter-field {
    width: 130px;
  }

  .filters-dropdown-filter-modifier {
    width: 90px;
  }

  .filters-dropdown-filter-value {
    min-width: 140px;
    max-width: 200px;
    height: 100%;
  }

  .filters-dropdown-filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    padding: 0;

    & > .icon {
      margin-right: 0;
    }
  }
  .select.select--is-secondary {
    .select-label {
      &--is-placeholder {
        color: $black;
      }
    }
    .select-input {
      background: #fff;
      padding: 6px 12px;
      height: 35px;
      min-height: 35px;
      max-height: 35px;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        background: #E5EBEB;
        box-shadow: none;
        outline: 1px solid $brandPrimary300;
      }
    }

    .select-dropdown {
      position: absolute;
      top: calc(100% + 4px);
    }
  }
}
</style>
